import $ from 'jquery';

window.$ = window.jQuery = $;

import SearchEngine from "./SearchEngine";

import * as bootstrap from 'bootstrap';

window.Cropper = require('cropperjs');

import Dropzone from 'dropzone';

window.Dropzone = Dropzone;

import JsonViewer from 'json-viewer-js';

window.JsonViewer = JsonViewer;

import cronstrue from 'cronstrue';

window.cronstrue = cronstrue;

import copy from 'copy-to-clipboard';

window.copy = copy;

import Tagify from '@yaireo/tagify';

window.Tagify = Tagify;

import './../admin-theme/src/assets/js/components/aside';
import './../admin-theme/src/assets/js/components/menu';
import './../admin-theme/src/assets/js/components/popover';
import './../admin-theme/src/assets/js/components/simplebar';
import './../admin-theme/src/assets/js/components/search';
import './../admin-theme/src/assets/js/components/tooltip';

$(document).ready(function () {

    console.log('mangasaki.fr [admin]');

    const csrf = $('[name="csrf-token"]').attr('content');

    // Search Engine Component
    new SearchEngine({
        selector: '.search-engine',
        hideClass: 'd-none',
        debug: true,
    });

    // Toast Notifications
    const notifications = document.querySelectorAll('.toast');
    const toastList = [...notifications].map(toastEl => {
        new bootstrap.Toast(toastEl, {
            autohide: false,
            delay: 15000,
        }).show();
    });

    let cropperList = {};

    // Tile Cropper Fields
    $('.file-cropper').each(function () {

        const $fileCropper = $(this);
        const field = $fileCropper.attr('data-field');

        // IMAGE CROPPER
        const $imageCrop = $fileCropper.find('.image-crop');
        const image = $imageCrop.find('img')[0];
        const ratio = $imageCrop.attr('data-ratio');
        const $input = $imageCrop.find('input.crop');

        let data = $input.val();
        try {
            data = $.parseJSON(data);
        } catch (ex) {
            data = {};
        }

        const cropper = new Cropper(image, {
            aspectRatio: ratio,
            data: data,
            rotatable: false,
            zoomable: false,
            viewMode: 2,
            crop(e) {
                $input.val(JSON.stringify(e.detail));
            },
        });
        cropperList[field] = cropper;
    });

    // IMAGE SELECTOR
    $(document).on('click', '.file-cropper .file-selector .file-item', function () {
        const $fileItem = $(this);
        const $modal = $(this).parents('.modal');
        const $fileCropper = $(this).parents('.file-cropper');
        const field = $fileCropper.attr('data-field');

        let filename = $(this).attr('data-filename');
        let filepath = $(this).find('img').attr('src');
        let $inputGroupFilename = $fileCropper.find('.input-group-filename');

        console.log($fileCropper.attr('data-field'), filepath)

        $inputGroupFilename.text(filename);

        $fileCropper.find('.file-selector .file-item').removeClass('checked');
        $(this).addClass('checked');

        $fileCropper.find('input.picture').val(filename);

        cropperList[field].replace(filepath, false);
        cropperList[field].reset();
    });

    $(document).on('shown.bs.modal', '.file-cropper .modal', function () {
        const $modal = $(this);
        const baseUrl = $modal.attr('data-action');
        const storage = $modal.attr('data-storage');

        const data = {
            storage: storage,
        };

        $.ajax({
            url: baseUrl,
            method: 'post',
            headers: {
                'X-CSRF-TOKEN': csrf,
            },
            dataType: 'json',
            timeout: 10000,
            data: data,
            beforeSend: function () {
                $modal.find('.file-selector .row').empty();
            },
            success: function (data, status, xhr) {
                $modal.find('.file-selector .row').html(data.data.html);
            },
            error: function (xhr, status, error) {
                console.log(xhr, status, error);
            }
        });
    });


    // Copy to Clipboard
    $(document).on('click', '.copy-tool', function () {
        let $element = $(this);
        if ($(this).attr('data-target') !== undefined) {
            let value = $($(this).attr('data-target')).val();
            copy(value);
        } else {
            let value = $(this).val();
            copy(value);
        }
    });

    // Tooltips
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl, {
        trigger: $(tooltipTriggerEl).attr('data-bs-trigger') || 'hover',
    }));

});
