import $ from "jquery";

export default class SearchEngine {

    defaultOptions = {
        selector: '.search-engine',
        targetSelector: 'table',
        itemSelector: 'tbody tr',
        sortSelector: 'thead [data-sort-field]',
        hideClass: 'd-none',
        debug: false,
    };

    options = {};
    instances = [];

    constructor(options) {
        this.options = $.extend(
            this.defaultOptions,
            options != null ? options : {}
        );

        this._initialize();
    }

    _initialize() {
        const $this = this;
        let $elements = $(this.options.selector);

        $this._log('_initialize', $this.options);

        $elements.each(function () {
            let $element = $(this);

            let targetSelector = $element.attr('data-target') || $this.options.targetSelector;
            let itemSelector = $element.attr('data-item') || $this.options.itemSelector;

            let $target = $(targetSelector);

            let instance = {
                'slug': $(targetSelector).attr('id'),                           // search engine id
                '$element': $element,                                           // search engine
                '$inputs': $element.find('input,select'),                       // filter fields
                '$button': $element.find('button'),                             // search engine validate button
                'targetSelector': targetSelector,                               // items container selector
                '$target': $target,                                             // items container
                '$sortingButtons': $target.find($this.options.sortSelector),    // sorting buttons
                'itemSelector': itemSelector,                                   // items selector
                'domInterval': undefined,                                       // DOM interval
            };
            $this.instances.push(instance);

            $this._initializeInstance(instance);
        });
    }

    _initializeInstance(instance) {
        const $this = this;

        $this._log('instance', instance);

        let searchData = localStorage.getItem(instance.slug);
        if (searchData !== undefined && searchData !== null) {
            searchData = JSON.parse(searchData);
        }
        if (searchData !== undefined && searchData !== null) {
            instance.$inputs.each(function () {
                let $input = $(this);
                $this._log('searchData', searchData);
                searchData.forEach(function (input, key) {
                    if ($input.attr('data-bind') === input.bind) {
                        $input.val(input.value);
                    }
                });
            });
            $this._filter(instance, searchData);
        }

        $this._bindInsertedResults(instance);
        $this._bindInputField(instance);
        $this._bindResetButton(instance);
        $this._bindSortingButtons(instance);
    }

    _bindInsertedResults(instance) {
        const $this = this;
        // DOMNodeRemoved
        instance.$target.on('DOMNodeInserted', function () {
            if (instance.domInterval !== undefined) {
                clearInterval(instance.domInterval);
            }
            instance.domInterval = setInterval(function () {
                $this._filter(instance);
                clearInterval(instance.domInterval);
                instance.domInterval = undefined;
            }, 100);
        });
    }

    _bindInputField(instance) {
        const $this = this;
        $this._log('_bindInputField', instance);

        instance.$inputs.on('keyup change', function () {
            let searchData = [];
            instance.$inputs.each(function () {
                searchData.push({
                    $input: $(this),
                    bind: $(this).attr('data-bind') ?? null,
                    value: $(this).val(),
                    strict: $(this)[0].hasAttribute('data-strict'),
                });
            });
            $this._filter(instance, searchData);
        });

    }

    _bindResetButton(instance) {
        const $this = this;
        instance.$button.on('click', function () {
            $this._log('_bindResetButton', instance);
            instance.$inputs.val('');
            $this._filter(instance);
        });
    }

    _bindSortingButtons(instance) {
        const $this = this;
        $this._log('_bindSortingButtons', instance);
        instance.$sortingButtons.on('click', function () {
            let $button = $(this);
            let field = $button.attr('data-sort-field');
            let numeric = $button.attr('data-sort-numeric') === '1';

            let order = 'asc';
            if ($button.attr('data-current') === 'asc') {
                order = 'desc';
            }

            instance.$sortingButtons.each(function () {
                let $item = $(this);
                $item.removeAttr('data-current');
                $item.find('.sort').addClass($this.options.hideClass);
                $item.find('.sort-default').removeClass($this.options.hideClass);
            });

            $button.attr('data-current', order);
            switch (order) {
                case 'asc':
                    $button.find('.sort').addClass($this.options.hideClass);
                    $button.find('.sort-asc').removeClass($this.options.hideClass);
                    break;
                case 'desc':
                    $button.find('.sort').addClass($this.options.hideClass);
                    $button.find('.sort-desc').removeClass($this.options.hideClass);
                    break;
                default:
                    $button.find('.sort').addClass($this.options.hideClass);
                    $button.find('.sort-default').removeClass($this.options.hideClass);
                    break;
            }

            $this._sort(instance, field, order, numeric);
        });
    }

    _filter(instance, searchData) {
        const $this = this;
        $this._log('_filter', instance, searchData);
        if (searchData !== undefined && searchData !== null) {
            localStorage.setItem(instance.slug, JSON.stringify(searchData));
        } else {
            localStorage.removeItem(instance.slug);
        }
        instance.$target.find(instance.itemSelector).each(function () {
            let show = true;
            let $item = $(this);
            if (searchData !== undefined && searchData !== null) {
                searchData.forEach(function (input, key) {
                    if (input.bind !== null && input.value !== '') {
                        let value = $item.attr('data-' + input.bind).toLowerCase().trim();
                        if (input.strict) {
                            if (value !== input.value.toLowerCase().trim()) {
                                show = false;
                            }
                        } else {
                            // remove double spaces
                            value = value.replace(/ +(?= )/g, '');
                            if ((value.indexOf(input.value.toLowerCase().trim()) === -1)) {
                                show = false;
                            }
                        }
                    }
                });
            }
            if (show) $item.removeClass($this.options.hideClass);
            else $item.addClass($this.options.hideClass);
        });
    }

    _sort(instance, field, order, isNumber) {
        const $this = this;
        $this._log('_sort', instance, field, order);
        let $sortedItems = instance.$target.find(instance.itemSelector).sort(function (a, b) {
            let valA = $(a).attr(`data-${field}`);
            let valB = $(b).attr(`data-${field}`);

            if (order === 'asc') {
                valA = $(b).attr(`data-${field}`);
                valB = $(a).attr(`data-${field}`);
            }

            if (isNumber) {
                valA = parseFloat(valA);
                valB = parseFloat(valB);
            }

            if (valB > valA) return 1;
            else if (valB < valA) return -1;
            return 0;
        });

        $sortedItems.eq(0).parent().append($sortedItems);
    }

    _log(...messages) {
        if (this.options.debug) {
            console.log('SearchEngine', ...messages);
        }
    }
}

export {SearchEngine};
