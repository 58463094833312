import $ from 'jquery';
import SimpleBar from 'simplebar';

$(document).ready(function () {
    const simpleBars = document.querySelectorAll('[data-pixr-simplebar]');

    simpleBars.forEach((simplebar) => {
        new SimpleBar(simplebar, {
            autoHide: false
        })
    });
});
